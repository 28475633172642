import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import Content from 'components/new/content'
import Layout from 'components/layout'
import { graphql, Link } from 'gatsby'
import H2 from 'components/new/typography/h2'
import H3 from 'components/new/typography/h3'
import P from 'components/new/typography/p'
import Spacer from 'components/new/spacer'
import Ul from 'components/new/ul'
import Button from 'components/button/button'
import { column } from 'styles/column'
import { clearfix } from 'styles/clearfix'
import { GatsbyImage } from 'gatsby-plugin-image'
import Youtube from 'components/youtube'
import Form from 'components/forms/connected-support'
import { useToggle } from '~/hooks/use-toggle'

const ConnectedSupportPage = ({ data }) => {
  const { heroImage, serivceAdvisorRemoteThumbnailImage, expertAlertsThumbnailImage } = data

  const [formModalOpen, toggleFormModal] = useToggle()

  return (
    <Layout>
      <Helmet>
        <title>Connected Support | Hutson Inc</title>
      </Helmet>

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Connected Support'
        overlayOpacity={0.3}
      />

      <Content kind='full'>
        <H2>Get connected, stay running</H2>
        <P>
          When you're out in the field, the last thing you want to worry about is breaking down.
          Connected Support is here to help reduce, or even prevent, downtime. Newer John Deere
          equipment comes straight from the factory with advanced monitoring technology already
          installed. Each machine is ready to alert you when something goes wrong—no matter where
          you are. Our team can also receive alerts and connect to your equipment to diagnose the
          problem. Hutson's Connected Support team is here to remotely monitor and proactively
          service your machines for increased uptime.
        </P>
        <P>
          Not only can you connect large equipment, you can also connect Gator utility vehicles,
          service trucks and more! With Operations Center, you're just a few clicks away from
          keeping a pulse on your entire fleet.
        </P>
        <Spacer size='l' variable />
        <H3>Benefits of connected machines</H3>
        <P>
          Having a connected machine enables you to remotely monitor it. You may also give us
          permissions to monistor your machine so we can let you know if something doesn't look
          right. Here's what all is included:
        </P>
        <StyledList>
          <li>
            <b>Machine Alerts</b>: Monitor alerts from your machine remotely
          </li>
          <li>
            <b>Machine Information</b>: Monitor engine hours, utilization, fuel level, idle time and
            more
          </li>
          <li>
            <b>Location History</b>: View machine location and get notified if it moves when it's
            not supposed to
          </li>
          <li>
            <b>Remote Support Tools</b>: Hutson can perform software update remotely or connect to
            your display and walk you through an issue
          </li>
          <li>
            <b>Expert Alerts</b>: Hutson can monitor machines and address potential issues
          </li>
        </StyledList>
        <Spacer size='m' variable />
        <ButtonContainer>
          <Button ghost color='green' onClick={toggleFormModal}>
            Request more information
          </Button>
        </ButtonContainer>
      </Content>
      <LightSection>
        <Content kind='full'>
          <Grid>
            <Column>
              <GatsbyImage
                image={serivceAdvisorRemoteThumbnailImage.childImageSharp.gatsbyImageData}
                alt=''
              />
              <ColumnContent>
                <H3>Remote support tools</H3>
                <P>
                  Given your consent, we can remotely access your in-cab display to assist with
                  setup and adjustments in near real time for optimal performance. We can also
                  perform remote diagnostics before making a trip to your machine for a repair and
                  perform software updates.
                </P>
                <Spacer size='s' />
                <ButtonContainer>
                  <Button
                    as={Link}
                    to='/connected-support/remote-support-tools/'
                    ghost
                    color='green'
                    aria-label='Learn more about remote support tools'
                  >
                    Learn more
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
            <Column>
              <GatsbyImage
                image={expertAlertsThumbnailImage.childImageSharp.gatsbyImageData}
                alt=''
              />
              <ColumnContent>
                <H3>Expert Alerts</H3>
                <P>
                  In certain situations, Hutson can make you aware of potential downtime based on
                  machine data. This allows us to remotely diagnose issues and fix them before they
                  become a problem.
                </P>
                <Spacer size='s' />
                <ButtonContainer>
                  <Button
                    as={Link}
                    to='/connected-support/expert-alerts/'
                    ghost
                    color='green'
                    aria-label='Learn more about Expert Alerts'
                  >
                    Learn more
                  </Button>
                </ButtonContainer>
              </ColumnContent>
            </Column>
          </Grid>
        </Content>
      </LightSection>
      <VideoSection>
        <VideoSectionColumn>
          <VideoContainer>
            <Youtube videoId='yFkklF-4Drc' />
          </VideoContainer>
        </VideoSectionColumn>
        <VideoSectionColumn>
          <Content kind='full'>
            <VideoSectionTitle>
              Hear what customers and technicians have to say about Connected Support
            </VideoSectionTitle>
            <VideoSectionP>
              Don't just take our word for it—see what actual Connected Support users have to say
              about the features and benefits of getting connected.
            </VideoSectionP>
          </Content>
        </VideoSectionColumn>
      </VideoSection>
      <Content kind='full'>
        <H2>What you need to get connected</H2>
        <P>
          To enable Connected Support, your machine must be factory-equipped or field-installed with
          a 4G modem with active service. You'll also need an Operations Center account with
          permissions given to Hutson to enable additional support like Expert Alerts.
        </P>
        <P>
          Note: Model Year 2014 - Current Large Ag Machines are currently enabled with Connected
          Support Technology. Model Year 2011-2013 Large Ag Machines can be upgraded with a 4G modem
          field kit.
        </P>
        <Spacer size='m' variable />
        <ButtonContainer>
          <Button ghost color='green' onClick={toggleFormModal}>
            Request more information
          </Button>
        </ButtonContainer>
      </Content>

      <Form toggleModal={toggleFormModal} modalOpen={formModalOpen} />
    </Layout>
  )
}

const LightSection = styled.div`
  background-color: ${props => props.theme.color.n20};
`

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media (min-width: 900px) {
    height: 450px;
  }
`

const StyledList = styled(Ul)`
  @media (min-width: 900px) {
    columns: 2;
    column-gap: ${props => props.theme.size.l};
  }
`

const ButtonContainer = styled.div`
  a {
    display: inline-block;
    text-decoration: none;
  }
`

const Grid = styled.div`
  @media (min-width: 900px) {
    ${clearfix}
  }
`

const Column = styled.div`
  background-color: #fff;

  @media (max-width: 899px) {
    :not(:last-child) {
      margin-bottom: ${props => props.theme.size.l};
    }
  }

  @media (min-width: 900px) {
    ${column('1/2')}
  }
`

const ColumnContent = styled.div`
  padding: ${props => props.theme.size.m};
`

const VideoSection = styled.div`
  background-color: ${props => props.theme.color.n700};

  @media (min-width: 900px) {
    display: flex;
  }
`

const VideoSectionColumn = styled.div`
  @media (min-width: 900px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
  }
`

const VideoContainer = styled.div`
  background-color: ${props => props.theme.color.n900};
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`

const VideoSectionTitle = styled(H2)`
  color: #fff;
`

const VideoSectionP = styled(P)`
  color: #fff;
`

export const pageQuery = graphql`
  query connectedSupportQuery {
    heroImage: file(relativePath: { eq: "connected-support/connected-support-hero.jpg" }) {
      ...FullWidthImage
    }
    serivceAdvisorRemoteThumbnailImage: file(
      relativePath: { eq: "connected-support/service-advisor-remote-thumbnail.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 765)
      }
    }
    expertAlertsThumbnailImage: file(
      relativePath: { eq: "connected-support/expert-alerts-thumbnail.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 765)
      }
    }
  }
`

export default ConnectedSupportPage
